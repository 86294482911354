import React from 'react';
import { createRoot } from 'react-dom/client'
import * as serviceWorker from './serviceWorker';
import './index.css';
import store from './app/store';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import MainNav from "./features/mainNav/MainNav";
import Home from "./pages/home";
import Projects from "./pages/projects";
import Blog from "./pages/blog";
import CookBooks from './pages/cookbooks';


const root = createRoot(document.getElementById('root'));

root.render(
    // <Provider store={store}>
    //     <BrowserRouter>
    //         <MainNav />
    //         <Routes>
    //             <Route exact path="/" element={<Home />} />
    //             <Route path="/projects" element={<Projects />} />
    //             <Route path="/blog" element={<Blog />} />
    //             <Route path="/cookbooks" element={<CookBooks />} />
    //         </Routes>
    //     </BrowserRouter>
    // </Provider>
    <p>There is nothing here.</p>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

