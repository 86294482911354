import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import config from '../config.json'
import { requestRepos } from '../apis/githubAPI'

// First, create the thunk
export const fetchProjects = createAsyncThunk(
  'github/fetchProjects',
  async () => requestRepos(config.github.username),
);

// Then, handle actions in your reducers:
const githubSlice = createSlice({
  name: 'github',
  initialState: {
    projects: [],
    isLoading: false,
    isDone: false,
    hasError: false
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchProjects.fulfilled]: (state, action) => {
      state.projects = action.payload
      state.isLoading = false
      state.isDone = true
      state.hasError = false
    },
    [fetchProjects.pending]: (state, action) => {
      state.isLoading = true
      state.isDone = false
      state.hasError = false
    },
    [fetchProjects.rejected]: (state, action) => {
      state.isLoading = false
      state.isDone = true
      state.hasError = true
    }
  }
});

export default githubSlice.reducer;

export const selectGithub = (state) => state.github;