import React from 'react';
import { Card } from 'react-bootstrap';
import '../styles/defaultPageStyle.css'
import './cookbooks.css';

const files = ["bashcookbook2e.epub", "bashcookbook2e.mobi", "bashcookbook2e.pdf", "concurrencyinccookbook2e.epub", "concurrencyinccookbook2e.mobi", "deeplearningcookbook.mobi", "deeplearningcookbook.pdf", "dockercookbook.epub", "dockercookbook.mobi", "dockercookbook.pdf", "javacookbook4e.epub", "javacookbook4e.mobi", "javacookbook4e.pdf", "javascriptcookbook3rdedition.mobi", "javascriptcookbook3rdedition.pdf", "kotlincookbook.epub", "kotlincookbook.mobi", "kotlincookbook.pdf", "machinelearningwithpythoncookbook.mobi", "machinelearningwithpythoncookbook.pdf", "powershellcookbook4e.pdf", "pythoncookbook3e.epub", "pythoncookbook3e.mobi", "pythoncookbook3e.pdf", "raspberrypicookbook3e.epub", "raspberrypicookbook3e.mobi", "raspberrypicookbook3e.pdf", "rcookbook2e.epub", "rcookbook2e.mobi", "rcookbook2e.pdf", "reactcookbook.epub", "reactcookbook.mobi", "reactcookbook.pdf", "regularexpressionscookbook2e.mobi", "regularexpressionscookbook2e.pdf", "unitygamedevelopmentcookbook.epub", "unitygamedevelopmentcookbook.mobi", "unitygamedevelopmentcookbook.pdf"]

export default class CookBooks extends React.Component {
    componentDidMount() {
        document.title = "WB| Cookbooks"
    }

    render() {
        return (
            <main>
                <h1>CookBooks</h1>
                <div className='files-container col-12'>
                    {files.map((file) => (
                        <Card className='col-2'>
                            <a
                                key={`file-${file}`}
                                target="_blank"
                                rel="noreferrer"
                                href={`https://w-b.sfo3.digitaloceanspaces.com/${file}`}
                            >
                                {file}
                            </a>
                        </Card>
                    ))}
                </div>
            </main>
        )
    }
}