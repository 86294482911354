import axios from 'axios';

const githubClient = axios.create({
  baseURL: 'https://api.github.com',
  headers: {
    "content-type": "application/json",
    "Accept": "application/json",
  },
});

export const requestRepos = async (username) => githubClient.get(`/users/${username}/repos`)
  .then(response => response.data);

