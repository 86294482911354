export const websiteDescription = {
    full_name: 'willbedu.com',
    id: '',
    description: 'This current site you are viewing',
    homepage: 'https://willbedu.com',
    stargazers_count: 'N/A',
    pushed_at: new Date(Date.parse('11/05/2023')),
    watchers_count: 'N/A',
    language: 'JavaScript',
    topics: ['Node.js', 'React', 'CICD'],
}

export const RESUME_EVENTS = [
    {
        title: 'Senior Full Stack Engineer - Marshall Associates',
        description: [
            'Lead development to create and deploy saas platforms using React',
            'Utilized AWS services such as Cognito and Lambdas to manage user authentication',
            'Developed Python microservices using FastApi and Flask', 'Deployed Infrastructure via Teraform IaC'
        ],
        startDate: '2021-12',
        type: 'employment'
    },
    {
        title: 'Software DevOps Engineer - Northern Trust Corporation',
        description: [
            'Contributed Typescript code to open-source library that was used to migrate work-tracking services to Azure Cloud',
            'Developed Terraform code to speed up and automate infrastructure deployment, management, and decomitioning',
            'Developed Azure DevOps pipelines and templates that automate creation, configuration, and release'
        ],
        startDate: '2020-1',
        endDate: '2021-12',
        type: 'employment'
    },
    {
        title: 'Site Reliability Engineer Associate| Platform Automation - Northern Trust Corporation',
        description: [
            'Designed, developed, deployed full stack of an application to monitor microservices and grant viability to user access',
            'Developed python and bash scripts for automated monitoring, parsing, and resource configuration',
            'Authored Ansible for VM creation and configuration and integrated Ansible into VMware vSphere'
        ],
        startDate: '2020-1',
        endDate: '2021-1',
        type: 'employment'
    },
    {
        title: 'Public Cloud Engineer| DevOps - Northern Trust Corporation',
        description: [
            'Minimize deployment time for application teams by authoring infrastructure as code',
            'Automated virtual machine configuration by developing Ansible roles',
            'Developed scripts to audit cloud environments for security, quality assurance, cost management, and to enforce policy'
        ],
        startDate: '2019-07',
        endDate: '2020-1',
        type: 'employment'
    },
    {
        title: 'Bachelor of Science - Computer Science - University of Illinois at Chicago (UIC)',
        description: [
            'Relevant Coursework: Software Engineering, Cloud Computing, Networks, Database Systems, Data Structures, Computer Algorithms, System Security, Visual Analytics, User Interface Design, Computer Architecture'
        ],
        startDate: '2019-04',
        type: 'education'
    },
    {
        title: 'Master of Science - Computer Science - University of Illinois at Chicago (UIC)',
        description: [],
        type: 'education'
    },
    {
        title: 'Service Desk Student Staff Supervisor - University of Illinois at Chicago - Student Affairs',
        description: [
            'Developed front-end production code through several sprints of the Agile Software Life Cycle',
            'Utilized Angular, React, and Redux framework to deliver front-end code that met user experience specifications',
            'Authored rest API on cloud platform to effectively load balance and achieve client satisfaction',
            'Designed relational database to allow for efficient indexing and fetching'
        ],
        startDate: '2017-12',
        endDate: '2019-04',
        type: 'employment'
    },

    {
        title: 'Software Engineering Intern - CDK Global at Hoffman Estates',
        description: [
            'Lead a team of 12 to provide support to over 30+ departments and achieve a 94% satisfaction rating',
            'Develop scripts to automate processes to ensure peak efficiency for team operations',
            'Train employees to communicate with clients in person and electronically and to gain client’s confidence and trust.',
            'Administrate remote deployment and management interface for 600+ machines'
        ],
        startDate: '2018-6',
        endDate: '2018-08',
        type: 'employment'
    },
]

export const STAGES_OF_LEARNING = [
    'Novice',
    'Beginner',
    'Intermediate',
    'Competent',
    'Proficient',
    'Advanced',
    'Expert'
];


export const RESUME_LANGUAGES = [
    {
        name: 'Bash',
        level: 3,
    },
    {
        name: 'Javascript',
        level: 7,
    },
    {
        name: 'TypeScript',
        level: 5,
    },
    {
        name: 'PowerShell',
        level: 4,
    },
    {
        name: 'Python',
        level: 6,
    },
    {
        name: 'SQL',
        level: 4,
    },
    {
        name: 'Terraform',
        level: 5,
    },
]

export const RESUME_FRAMEWORKS = [
    {
        name: 'Angular',
        level: 3,
    },
    {
        name: 'Bootstrap',
        level: 6,
    },
    {
        name: 'Express',
        level: 6,
    },
    {
        name: 'jQuery',
        level: 3,
    },
    {
        name: 'React',
        level: 7,
    },
    {
        name: 'Redux',
        level: 6,
    }
];

export const RESUME_TOOLS = [
    {
        name: 'Ansible',
        level: 7,
    },
    {
        name: 'AWS',
        level: 5,
    },
    {
        name: 'Azure Cloud',
        level: 5,
    },
    {
        name: 'Digital Ocean',
        level: 6,
    },
    {
        name: 'Docker',
        level: 6,
    },
    {
        name: 'Git',
        level: 6,
    },
    {
        name: 'Google Cloud Firebase',
        level: 5,
    },
    {
        name: 'Linux',
        level: 5,
    },
    {
        name: 'NGINX',
        level: 5,
    },
    {
        name: 'Node.js',
        level: 7,
    },
]